$gutter: 1rem; //for primeflex grid system

@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeflex/primeflex.scss";
@import "~primeicons/primeicons.css";
@import "https://i4m.i4go.com/css/wallets.css";
@import "~ladda/dist/ladda.min.css";

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background: #fbfcfe;
  // overflow-x: hidden;
}

@font-face {
  font-family: "AktivGroteskCorp";
  src: local("AktivGroteskCorp"),
    url(./assets/fonts/AktivGroteskCorp/AktivGroteskCorp-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "AktivGroteskCorp-Light";
  src: local("AktivGroteskCorp-Light"),
    url(./assets/fonts/AktivGroteskCorp/AktivGroteskCorp-Light.ttf)
      format("truetype");
}

button.btn-primary {
  background: #1a1a1a;
  color: white;
  padding: 13px 20px 13px 20px;
  height: 40px;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #1a91ff;
  }
  &:disabled {
    background: #c2c5c9;
  }
}

button.btn-primary-lg {
  background: #1a1a1a;
  color: white;
  padding: 16px 50px 16px 50px;
  height: 55px;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #1a91ff !important;
  }
  &:disabled {
    background: #c2c5c9 !important;
  }
}

button.btn-secondary {
  background: white;
  color: #1a1a1a;
  padding: 13px 20px 13px 20px;
  height: 40px;
  border: 1px solid #C2C5C9;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #1a1a1a !important;
    color: white !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:disabled {
    background: #c2c5c9 !important;
  }
}

button.btn-confirm {
  background: #1a1a1a;
  color: white;
  padding: 9.5px 20px 9.5px 20px;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 10px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #2d3759 !important;
  }
  &:disabled {
    background: #c2c5c9 !important;
  }
}

button.btn-cancel {
  background: #fafcfe;
  color: #141725;
  padding: 9.5px 20px 9.5px 20px;
  border: 1px solid #c2c5c9;
  border-radius: 50px;
  font-weight: 700;
  font-size: 10px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #f1f4f8 !important;
  }
  &:disabled {
    background: #c2c5c9 !important;
    color: #757575 !important;
  }
}

button.btn-cta {
  background: #0085ff;
  color: white;
  padding: 16px 50px 16px 50px;
  height: 55px;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: #1a91ff !important;
  }
  &:disabled {
    background: #c2c5c9 !important;
  }
}

button.btn-toggle {
  background: #dddfe2;
  color: black;
  padding: 14px 20px 14px 20px;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 13px;
  font-family: "AktivGroteskCorp-Light";
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  cursor: pointer;
  &.active {
    background: black !important;
    color: white !important;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #dddfe2;
}

::-webkit-scrollbar-thumb {
  background-color: #007cee;
}

.p-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.p-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.sidebar-dynamic {
  width: 375px !important;
}
@media only screen and (max-width: 640px) {
  .sidebar-dynamic {
    width: 100% !important;
  }
}

.dialog-dynamic {
  width: 50vw;
}
@media only screen and (max-width: 960px) {
  .dialog-dynamic {
    width: 75vw;
  }
}
@media only screen and (max-width: 640px) {
  .dialog-dynamic {
    width: 100vw;
    max-height: 100% !important;
    height: 100% !important;
  }
}

.dialog-order-details {
  .p-dialog-content {
    padding: 0px;
  }
}
